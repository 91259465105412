// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  properties: '/search/properties',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '404',
  page500: '/500',
  components: '/components',
  portal: '/portal'
};

export const PATH_DASHBOARD = {
  blog: {
    root: path('/blog'),
    posts: path('/blog/posts'),
    new: path('/blog/new'),
    view: (id) => `/property-details/${id}`,
  },
};
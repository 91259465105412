import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';

// components
import { BlogPostCard } from '../@dashboard/blog';
import { SkeletonPostItem } from '../../components/skeleton';
import EmptyContent from '../../components/EmptyContent';


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(4),
  borderRadius: Number(theme.shape.borderRadius) * 2,
}));

// ----------------------------------------------------------------------
HomeAdvertisement.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

export default function HomeAdvertisement({properties, isLoading}) {

  return (
    <Container>
      <ContentStyle>
      <Grid container spacing={3}>
          {(isLoading ? [...Array(12)] : properties).map((post, index) =>
            post ? (
              <Grid key={post.id} item xs={12} sm={6} md={(index === 0 && 6) || 3}>
                <BlogPostCard post={post} index={index} />
              </Grid>
            ) : (
              <SkeletonPostItem key={index} />
            )
          )}
          
        </Grid>
        {
          !isLoading && properties.length === 0 && (
            <EmptyContent
            title="No se encontraron resultados"
            description="Por favor, intente con otros filtros"
          />
          )
        }
      </ContentStyle>
    </Container>
  );
}

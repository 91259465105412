import React from 'react';
// @mui
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

import { Box, Container, Stack, Grid, Card, Chip, TextField, Autocomplete } from '@mui/material';
// components
import { MotionContainer, TextAnimate, varFade } from '../../components/animate';
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundImage:
    'url(https://s3.amazonaws.com/benboot.com/Images/overlay.svg), url(https://s3.amazonaws.com/benboot.com/Images/Montevideo.jpeg)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled(Stack)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    width: '100%',
    position: 'absolute',
    bottom: theme.spacing(10),
    paddingRight: theme.spacing(6),
  },
}));


HomeHero.propTypes = {
  states: PropTypes.array,
  types: PropTypes.array,
  operationSelected: PropTypes.func,
  operation: PropTypes.array,
  code: PropTypes.string,
};

export default function HomeHero({ states, types, operation, operationSelected, code }) {

  const [stateValue, setStateValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [operationValue, setOperationValue] = React.useState('');

  const handleProperties = () => {
    const query = {};
    if (stateValue) {
      query.state = stateValue;
    }
    if (typeValue) {
      query.type = typeValue;
    }
    if (operationValue) {
      query.operation = operationValue;
    }
    if (code) {
      query.code = code;
    }
    window.location.href = `${PATH_PAGE.properties}?${Object.keys(query).map(key => `${key}=${query[key]}`).join('&')}`;
  }
  
  return (
    <RootStyle>
      <Container component={MotionContainer} sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle spacing={5}>
          <Grid sx={{ mb: 3 }}>
            <TextAnimate text="Encuentra" sx={{ color: 'primary.main', mr: 2 }} variants={varFade().inRight} />
            <TextAnimate text="tu" sx={{ color: 'primary.main' }} variants={varFade().inRight} />
            <br />
            <Box sx={{ display: 'inline-flex', color: 'common.white' }}>
              <TextAnimate text="hogar" sx={{ mr: 2 }} />
              <TextAnimate text="ideal" />
            </Box>
          </Grid>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3} >
              <Grid container spacing={3} >
                <Grid item xs={12} md={3} key={1}>
                  <Autocomplete
                    multiple={false}
                    freeSolo
                    onChange={(event, newValue) => {
                      setStateValue(newValue);
                    }}
                    options={states.map((option) => option.name)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option} size="large" label={option} />
                      ))
                    }
                    renderInput={(params) => <TextField label="Ubicación" {...params} />}
                  />
                </Grid>
                <Grid item xs={12} md={3} key={2}>
                  <Autocomplete
                    multiple={false}
                    freeSolo
                    onChange={(event, newValue) => {
                      const selected = types.find((state) => state.name === newValue);
                      setTypeValue(newValue);
                      operationSelected(selected.id);
                    }}
                    options={types.map((option) => option.name)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                      ))
                    }
                    renderInput={(params) => <TextField label="Tipo" {...params} />}
                  />
                </Grid>
                <Grid item xs={12} md={3} key={3}>
                  <Autocomplete
                    multiple={false}
                    freeSolo
                    disabled={operation.length === 0}
                    onChange={(event, newValue) => {
                      setOperationValue(newValue);
                    }}
                    options={operation.map((option) => option.name)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                      ))
                    }
                    renderInput={(params) => <TextField label="Operación" {...params} />}
                  />
                </Grid>
                <Grid item xs={12} md={3} key={4}>
                  <LoadingButton 
                  variant="contained"
                   sx={{ width: '100%' }}
                   size="large" 
                   disabled={false}
                   onClick = {handleProperties}>
                    Buscar Inmueble
                  </LoadingButton>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </ContentStyle>
        
      </Container>
    </RootStyle>
  );
}

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {paramCase} from 'change-case';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Card, Avatar, Typography, CardContent, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
import { fDate } from '../../../utils/formatTime';
// components
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import TextMaxLine from '../../../components/TextMaxLine';
import TextIconLabel from '../../../components/TextIconLabel';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Label from '../../../components/Label';
import { fNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.8),
}));

// ----------------------------------------------------------------------
const PriceStyle = styled('h4')(({ theme }) => ({
  ...theme.typography.h2,
  top: 0,
  zIndex: 2,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  paddingTop: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(6),
  },
}));

const PriceStyleSecond = styled('h4')(({ theme }) => ({
  ...theme.typography.h2,
  top: 0,
  zIndex: 2,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  paddingTop: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard({ post, index }) {
  const isDesktop = useResponsive('up', 'md');

  const {
    thumbnail,
    title,
    agent,
    dateCreated,
    beedRooms,
    bathrooms,
    totalArea,
    idProperty,
    operationType,
    price,
    currency,
  } = post;

  const latestPost = index === 0 || index === 1 || index === 2;

  const handleProfile = () => {
    const paths = paramCase(agent.company ?? agent.displayName);
    window.location.href = `/${paths.toLowerCase()}`;
  };

  if (isDesktop && latestPost) {
    return (
      <Card>
        <Avatar
          alt={agent.displayName}
          src={agent.image}
          onClick={handleProfile}
          sx={{
            zIndex: 9,
            top: 24,
            left: 24,
            width: 40,
            height: 40,
            position: 'absolute',
            cursor: 'pointer',
          }}
        />
        <PriceStyle sx={{ spacing: 3 }}>
          <Label
            variant={'filled'}
            color={operationType.value === 'Alquiler' ? 'info' : 'success'}
            sx={{ textTransform: 'uppercase', fontSize: 12, mr: 2, p: 1 }}
          >
            {operationType.value}
          </Label>

          <Label sx={{ fontSize: 12, p: 1 }} variant={'filled'}>
            &nbsp;{`${currency} ${fNumber(price)}`}
          </Label>
        </PriceStyle>

        <PostContent
          id={idProperty}
          title={title}
          beedRooms={beedRooms ? beedRooms.value_name : 0}
          bathrooms={bathrooms ? bathrooms.value_name : 0}
          totalArea={totalArea ? totalArea.value : 0}
          createdAt={dateCreated}
          index={index}
        />
        <OverlayStyle />
        <Image alt="cover" src={thumbnail} sx={{ height: 360 }} />
      </Card>
    );
  }

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        <SvgIconStyle
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: 'absolute',
            color: 'background.paper',
          }}
        />
        <Avatar
          alt={agent.displayName}
          src={agent.image}
          onClick={handleProfile}
          sx={{
            left: 24,
            zIndex: 9,
            width: 32,
            height: 32,
            bottom: -16,
            position: 'absolute',
            cursor: 'pointer',
          }}
        />
        <Image alt="cover" src={thumbnail} ratio="4/3" />
      </Box>
      <PriceStyleSecond sx={{ spacing: 3 }}>
        <Label
          variant={'filled'}
          color={operationType.value === 'Alquiler' ? 'info' : 'success'}
          sx={{ textTransform: 'uppercase', fontSize: 12, mr: 2, p: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)' }}
        >
          {operationType.value}
        </Label>

        <Label sx={{ fontSize: 12, p: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)' }} variant={'filled'}>
          &nbsp;{`${currency} ${fNumber(price)}`}
        </Label>
      </PriceStyleSecond>
      <PostContent
        id={idProperty}
        title={title}
        beedRooms={beedRooms ? beedRooms.value_name : 0}
        bathrooms={bathrooms ? bathrooms.value_name : 0}
        totalArea={totalArea ? totalArea.value : 0}
        createdAt={dateCreated}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

PostContent.propTypes = {
  beedRooms: PropTypes.number,
  createdAt: PropTypes.string,
  index: PropTypes.number,
  totalArea: PropTypes.string,
  title: PropTypes.string,
  bathrooms: PropTypes.number,
  id: PropTypes.string,
};

export function PostContent({ id, title, beedRooms, bathrooms, totalArea, createdAt, index }) {
  const isDesktop = useResponsive('up', 'md');

  const linkTo = `${PATH_DASHBOARD.blog.view(id)}`;

  const latestPostLarge = index === 0;
  const latestPostSmall = index === 1 || index === 2;

  const POST_INFO = [
    { number: bathrooms, icon: 'fa-solid:bath' },
    { number: beedRooms, icon: 'fa:bed' },
    { number: totalArea, icon: 'bx:area' },
  ];

  return (
    <CardContent
      sx={{
        pt: 4.5,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
      }}
    >
      <Typography
        gutterBottom
        variant="caption"
        component="div"
        sx={{
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
        }}
      >
        {fDate(createdAt)}
      </Typography>

      <Link to={linkTo} color="inherit" component={RouterLink}>
        <TextMaxLine variant={isDesktop && latestPostLarge ? 'h5' : 'subtitle2'} line={3} persistent>
          {title}
        </TextMaxLine>
      </Link>

      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="flex-end"
        sx={{
          mt: 3,
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.94,
            color: 'common.white',
          }),
        }}
      >
        {POST_INFO.map((info, index) => (
          <TextIconLabel
            key={index}
            icon={<Iconify icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />}
            value={info.number}
            sx={{ typography: 'caption', ml: index === 0 ? 0 : 1.5 }}
          />
        ))}
      </Stack>
    </CardContent>
  );
}

import PropTypes from 'prop-types';
// @mui
import {paramCase} from 'change-case';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { Box , Avatar, SpeedDial, Typography, SpeedDialAction } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
import { fDate } from '../../../utils/formatTime';
// components
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
// utils
import { fNumber } from '../../../utils/formatNumber';
// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'Llamar',
    icon: <Iconify icon="eva:phone-call-outline" width={20} height={20}/>,
  },
  {
    name: 'Whatsapp',
    icon: <Iconify icon="cib-whatsapp" width={20} height={20}/>,
  }
];

// ----------------------------------------------------------------------
const OverlayStyle = styled('h1')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.72),
}));
// ----------------------------------------------------------------------
const TitleStyle = styled('h1')(({ theme }) => ({
  ...theme.typography.h2,
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  fontSize: theme.typography.h4.fontSize,
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10),
    paddingTop: theme.spacing(6),
    fontSize: theme.typography.h1.fontSize,
  },
}));
// ----------------------------------------------------------------------

const FooterStyle = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

const PriceStyle = styled('h1')(({ theme }) => ({
  ...theme.typography.h2,
  top: 0,
  zIndex: 14,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(6),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10),
    paddingTop: theme.spacing(18),
    
  },
}));

BlogPostHero.propTypes = {
  post: PropTypes.object.isRequired,
};
/* eslint-disable */

export default function BlogPostHero({ post }) {
  const { thumbnail, title, agent, date_created, operation_type, price, currency, id_property } = post;
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');

  const handleTapSocial = (name) => {
    if (name === 'Llamar') {
      window.open(`tel:${agent.phone}`);
    } else {
      window.open(`https://api.whatsapp.com/send/?phone=${agent.phone}&text=Hola%2C+te+escribo+desde+la+web+de+BenBoot+para+saber+sobre+esta+propiedad+https://benboot.com/property-details/${id_property}&app_absent=0`); 
    }
  }
  const handleProfile = () => {
    const paths = paramCase(agent.company ?? agent.displayName)
    window.location.href = `/${paths.toLowerCase()}`;
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <TitleStyle>{title}</TitleStyle>
      <PriceStyle sx={{ spacing: 3 }}>
        <Label
          variant={'filled'}
          color={operation_type.value === 'Alquiler' ? 'info' : 'success'}
          sx={{ textTransform: 'uppercase', fontSize: isDesktop ? 20 : 12, mr:2, p: isDesktop ? 2 : 1  }}
          
        >
          {operation_type.value}
        </Label>

        <Label sx={{ fontSize: isDesktop ? 20 : 12, p:  isDesktop ? 2 : 1  }} variant={'filled'}>
          &nbsp;{`${currency} ${fNumber(price)}`}
        </Label>
      </PriceStyle>

      <FooterStyle >
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }} onClick={handleProfile}>
          <Avatar alt={agent.displayName} src={agent.image} sx={{ width: 48, height: 48 }} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
              {agent.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'grey.500' }}>
              {fDate(date_created)}
            </Typography>
          </Box>
        </Box>

        <SpeedDial
          direction={isDesktop ? 'left' : 'up'}
          ariaLabel="Share post"
          icon={<Iconify icon="eva:more-vertical-outline" sx={{ width: 20, height: 20 }} />}
          sx={{ '& .MuiSpeedDial-fab': { width: 48, height: 48 } }}

        >
          {SOCIALS.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement="top"
              FabProps={{ color: 'default' }}
              onClick={() => handleTapSocial(action.name)}
            />
            
          ))}
        </SpeedDial>
      </FooterStyle>

      <OverlayStyle />
       <Image alt="post cover" src={thumbnail} ratio="16/9" /> 
    </Box>
  );
}

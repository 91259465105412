export const privacy = `
<p>Esta Pol&iacute;tica de privacidad describe c&oacute;mo se recopila, utiliza y comparte su informaci&oacute;n personal cuando visita o hace una compra en benboot.com (denominado en lo sucesivo el &ldquo;Sitio&rdquo;).</p>
<p><br></p>
<p><strong><em>INFORMACI&Oacute;N PERSONAL QUE RECOPILAMOS</em></strong></p>
<p>Cuando visita el Sitio, recopilamos autom&aacute;ticamente cierta informaci&oacute;n sobre su dispositivo, incluida informaci&oacute;n sobre su navegador web, direcci&oacute;n IP, zona horaria y algunas de las cookies que est&aacute;n instaladas en su dispositivo. Adem&aacute;s, a medida que navega por el Sitio, recopilamos informaci&oacute;n sobre las p&aacute;ginas web individuales o los productos que ve, las p&aacute;ginas web o los t&eacute;rminos de b&uacute;squeda que lo remitieron al Sitio e informaci&oacute;n sobre c&oacute;mo interact&uacute;a usted con el Sitio. Nos referimos a esta informaci&oacute;n recopilada autom&aacute;ticamente como &ldquo;Informaci&oacute;n del dispositivo&rdquo;.</p>
<p><br></p>
<p>Recopilamos Informaci&oacute;n del dispositivo mediante el uso de las siguientes tecnolog&iacute;as:</p>
<p><br></p>
<p><strong><em>&nbsp;COOKIES</em></strong></p>
<p>Aqu&iacute; tienes una lista de cookies que utilizamos. Las enlistamos para que puedas elegir s&iacute;&nbsp;</p>
<p>- Los &ldquo;Archivos de registro&rdquo; rastrean las acciones que ocurren en el Sitio y recopilan datos, incluyendo su direcci&oacute;n IP, tipo de navegador, proveedor de servicio de Internet, p&aacute;ginas de referencia/salida y marcas de fecha/horario.</p>
<p>&nbsp; &nbsp; - Las &ldquo;balizas web&rdquo;, las &ldquo;etiquetas&rdquo; y los &ldquo;p&iacute;xeles&rdquo; son archivos electr&oacute;nicos utilizados para registrar informaci&oacute;n sobre c&oacute;mo navega usted por el Sitio.</p>
<p><br></p>
<p>Adem&aacute;s, cuando hace una compra o intenta hacer una compra a trav&eacute;s del Sitio, recopilamos cierta informaci&oacute;n de usted, entre la que se incluye su nombre, informaci&oacute;n de pago (incluidos los n&uacute;meros de la tarjeta de cr&eacute;dito, direcci&oacute;n de correo electr&oacute;nico y n&uacute;mero de tel&eacute;fono. &nbsp;Nos referimos a esta informaci&oacute;n como &ldquo;Informaci&oacute;n de compra&rdquo;.</p>
<p>Cuando hablamos de &ldquo;Informaci&oacute;n personal&rdquo; en la presente Pol&iacute;tica de privacidad, nos referimos tanto a la Informaci&oacute;n del dispositivo como a la Informaci&oacute;n de compra.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><strong><em>&iquest;C&Oacute;MO UTILIZAMOS SU INFORMACI&Oacute;N PERSONAL?</em></strong></p>
<p>Usamos la Informaci&oacute;n de compra que recopilamos en general para preparar las compras realizadas a trav&eacute;s del Sitio (incluido el procesamiento de su informaci&oacute;n de pago, la creaci&oacute;n de usuarios y la entrega de facturas y/o confirmaciones de compra). &nbsp;Adem&aacute;s, utilizamos esta Informaci&oacute;n de compra para: comunicarnos con usted;</p>
<p>examinar nuestras compras en busca de fraudes o riesgos potenciales; y cuando de acuerdo con las preferencias que usted comparti&oacute; con nosotros, le proporcionamos informaci&oacute;n o publicidad relacionada con nuestros productos o servicios.</p>
<p><br></p>
<p>Utilizamos la Informaci&oacute;n del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su direcci&oacute;n IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estad&iacute;sticas sobre c&oacute;mo nuestros clientes navegan e interact&uacute;an con el Sitio y para evaluar el &eacute;xito de nuestras campa&ntilde;as publicitarias y de marketing).</p>
<p><br></p>
<p><strong><em>COMPARTIR SU INFORMACI&Oacute;N PERSONAL</em></strong></p>
<p>Compartimos su Informaci&oacute;n personal con terceros para que nos ayuden a utilizar su Informaci&oacute;n personal, tal como se describi&oacute; anteriormente. &nbsp;Por ejemplo, utilizamos la tecnolog&iacute;a de Amazon AWS en nuestra plataforma online. Puede obtener m&aacute;s informaci&oacute;n sobre c&oacute;mo Amazon AWS utiliza su Informaci&oacute;n personal aqu&iacute;:&nbsp;<a href="https://aws.amazon.com/es/privacy/" rel="noopener noreferrer" target="_blank">https://aws.amazon.com/es/privacy/</a>, utilizamos adem&aacute;s tecnolog&iacute;a de Amazon AWS en nuestra plataforma online. Puede obtener m&aacute;s informaci&oacute;n sobre c&oacute;mo Heroku utiliza su Informaci&oacute;n personal aqu&iacute;:&nbsp;<a href="https://www.heroku.com/policy/security" rel="noopener noreferrer" target="_blank">https://www.heroku.com/policy/security</a>.</p>
<p>Tambi&eacute;n utilizamos Google Analytics para ayudarnos a comprender c&oacute;mo usan nuestros clientes el Sitio. Puede obtener m&aacute;s informaci&oacute;n sobre c&oacute;mo Google utiliza su Informaci&oacute;n personal aqu&iacute;: https://www.google.com/intl/es/policies/privacy/. &nbsp;Puede darse de baja de Google Analytics aqu&iacute;: https://tools.google.com/dlpage/gaoptout.</p>
<p><br></p>
<p>Podemos compartir su Informaci&oacute;n personal para cumplir con las leyes y regulaciones aplicables, para responder a una citaci&oacute;n, orden de registro u otra solicitud legal de informaci&oacute;n que recibamos, o para proteger nuestros derechos.</p>
<p>Finalmente, tambi&eacute;n podemos incluir su informaci&oacute;n personal en campa&ntilde;as de remarketing, publicidad dirigida o publicidad orientada por el comportamiento.</p>
<p>Como se describi&oacute; anteriormente, utilizamos su Informaci&oacute;n personal para proporcionarle anuncios publicitarios dirigidos o comunicaciones de marketing que creemos que pueden ser de su inter&eacute;s. &nbsp;Para m&aacute;s informaci&oacute;n sobre c&oacute;mo funciona la publicidad dirigida, puede visitar la p&aacute;gina educativa de la Iniciativa Publicitaria en la Red (&quot;NAI&quot; por sus siglas en ingl&eacute;s) en http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
<p><br></p>
<p>Puede darse de baja de la publicidad dirigida mediante los siguientes enlaces:</p>
<p><br></p>
<p><strong>FACEBOOK:</strong> https://www.facebook.com/settings/?tab=ads</p>
<p><strong>GOOGLE:</strong> <a href="https://adssettings.google.com/authenticated?hl=es" rel="noopener noreferrer" target="_blank">https://adssettings.google.com/authenticated?hl=es</a></p>
<p><strong>BING:</strong> about.ads.microsoft.com/es-es/recursos/directivas/anuncios-personalizados</p>
<p><br></p>
<p>Adem&aacute;s, puede darse de baja de algunos de estos servicios visitando el portal de exclusi&oacute;n voluntaria de Digital Advertising Alliance en: ttp://optout.aboutads.info/.</p>
<p><br></p>
<p><strong><em>NO RASTREAR</em></strong></p>
<p>Tenga en cuenta que no alteramos las pr&aacute;cticas de recopilaci&oacute;n y uso de datos de nuestro Sitio cuando vemos una se&ntilde;al de No rastrear desde su navegador.</p>
<p><br></p>
<p><strong><em>SUS DERECHOS</em></strong></p>
<p>Si usted es un residente europeo, tiene derecho a acceder a la informaci&oacute;n personal que tenemos sobre usted y a solicitar que su informaci&oacute;n personal sea corregida, actualizada o eliminada. Si desea ejercer este derecho, comun&iacute;quese con nosotros a trav&eacute;s de la informaci&oacute;n de contacto que se encuentra a continuaci&oacute;n.</p>
<p>Adem&aacute;s, si usted es un residente europeo, tenemos en cuenta que estamos procesando su informaci&oacute;n para cumplir con los contratos que podamos tener con usted (por ejemplo, si realiza una compra a trav&eacute;s del Sitio) o para perseguir nuestros intereses comerciales leg&iacute;timos enumerados anteriormente. &nbsp;Adem&aacute;s, tenga en cuenta que su informaci&oacute;n se transferir&aacute; fuera de Europa, incluidos Canad&aacute; y los Estados Unidos.</p>
<p><br></p>
<p><strong><em>RETENCI&Oacute;N DE DATOS</em></strong></p>
<p>Cuando realiza una compra a trav&eacute;s del Sitio, mantendremos su Informaci&oacute;n de compra para nuestros registros a menos que y hasta que nos pida que eliminemos esta informaci&oacute;n.</p>
<p><br></p>
<p><strong><em>MENORES</em></strong></p>
<p>El sitio no est&aacute; destinado a personas menores de 18 a&ntilde;os.</p>
<p><br></p>
<p><strong><em>CAMBIOS</em></strong></p>
<p>Podemos actualizar esta pol&iacute;tica de privacidad peri&oacute;dicamente para reflejar, por ejemplo, cambios en nuestras pr&aacute;cticas o por otros motivos operativos, legales o reglamentarios.</p>
<p><br></p>
<p><strong><em>CONT&Aacute;CTENOS</em></strong></p>
<p>Para obtener m&aacute;s informaci&oacute;n sobre nuestras pr&aacute;cticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, cont&aacute;ctenos por correo electr&oacute;nico a info@benboot.com o por correo mediante el uso de la informaci&oacute;n que se proporciona a continuaci&oacute;n:</p>
<p>&nbsp;25 de Mayo 713, Montevideo, Montevideo, 11000, Uruguay</p>
<p>&mdash;-------------------------------------------------------------------------------------------------------------</p>
<p><br></p>
<p><br></p>
<p><strong>T&Eacute;RMINOS DEL SERVICIO</strong></p>
<p><br></p>
<p><strong><em>GENERALIDADES</em></strong></p>
<p>BenBoot gestiona este sitio web. En todo el sitio, los t&eacute;rminos &quot;nosotros&quot;, &quot;nos&quot; y &quot;nuestro&quot; se refieren en lo sucesivo a BenBoot. BenBoot ofrece esta p&aacute;gina web, incluida toda la informaci&oacute;n, las herramientas y los servicios que se ponen en este sitio a disposici&oacute;n suya, el usuario, siempre y cuando acepte la totalidad de los t&eacute;rminos, condiciones, pol&iacute;ticas y avisos contemplados aqu&iacute;.</p>
<p><br></p>
<p>Al visitar nuestro sitio y/o comprarnos algo, usted interact&uacute;a con nuestro &quot;Servicio&quot; y reconoce como vinculantes los siguientes t&eacute;rminos y condiciones (denominados en lo sucesivo &quot;T&eacute;rminos del servicio&quot;, &quot;T&eacute;rminos&quot;), incluidos aquellos t&eacute;rminos y condiciones adicionales y las pol&iacute;ticas que se mencionan aqu&iacute; y/o disponibles por medio de hiperv&iacute;nculo. Estos T&eacute;rminos del servicio se aplican a todos los usuarios del sitio, incluyendo de manera enunciativa m&aacute;s no limitativa los usuarios que son navegadores, proveedores, clientes, comerciantes y/o que aporten contenido.</p>
<p>Lea estos T&eacute;rminos del servicio detenidamente antes de acceder o utilizar nuestra p&aacute;gina web. Al acceder o utilizar cualquier parte del sitio, usted acepta estos T&eacute;rminos del servicio. Si no acepta la totalidad de los t&eacute;rminos y condiciones de este acuerdo, no podr&aacute; acceder al sitio web ni utilizar ning&uacute;n servicio. Si estos T&eacute;rminos del servicio se considerasen una oferta, la aceptaci&oacute;n se limita expresamente a los presentes T&eacute;rminos del servicio.</p>
<p><br></p>
<p>Las nuevas funciones o herramientas que se agreguen a la plataforma actual tambi&eacute;n estar&aacute;n sujetas a los T&eacute;rminos del servicio. Puede revisar la versi&oacute;n m&aacute;s reciente de los T&eacute;rminos del servicio en cualquier momento en esta p&aacute;gina. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los presentes T&eacute;rminos del servicio mediante la publicaci&oacute;n de actualizaciones o cambios en nuestra p&aacute;gina web. Es su responsabilidad revisar esta p&aacute;gina peri&oacute;dicamente para ver los cambios. Su uso de la p&aacute;gina web o el acceso a ella de forma continuada despu&eacute;s de la publicaci&oacute;n de cualquier cambio constituye la aceptaci&oacute;n de dichos cambios.</p>
<p>Nuestra plataforma est&aacute; alojada en Amazon AWS y Heroku.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 1: T&Eacute;RMINOS DE LA PLATAFORMA</em></strong></p>
<p>Al aceptar los presentes T&eacute;rminos del servicio, usted declara que tiene la mayor&iacute;a de edad en su estado o provincia de residencia, o que es mayor de edad en su estado o provincia de residencia y que nos ha dado su consentimiento para permitir que cualquiera de las personas menores que dependen de usted utilice este sitio.</p>
<p><br></p>
<p>No puede utilizar nuestros productos para ning&uacute;n fin ilegal o no autorizado ni puede, al hacer uso del Servicio, infringir las leyes de su jurisdicci&oacute;n (incluyendo de manera enunciativa m&aacute;s no limitativa, las leyes de derechos de autor).</p>
<p>No transmitir&aacute; ning&uacute;n gusano o virus inform&aacute;ticos ni ning&uacute;n c&oacute;digo de naturaleza destructiva.</p>
<p>El incumplimiento o violaci&oacute;n de cualquiera de los T&eacute;rminos dar&aacute; como resultado la rescisi&oacute;n inmediata de sus Servicios.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 2: CONDICIONES GENERALES</em></strong></p>
<p>Nos reservamos el derecho de rechazar el servicio a cualquier persona, por cualquier motivo, en cualquier momento.</p>
<p><br></p>
<p>Usted comprende que su contenido (sin incluir la informaci&oacute;n de la tarjeta de cr&eacute;dito) puede transferirse sin cifrar e implicar (a) transmisiones en varias redes; y (b) cambios para adaptarse a los requisitos t&eacute;cnicos de conexi&oacute;n de redes o dispositivos y cumplir con ellos. La informaci&oacute;n de la tarjeta de cr&eacute;dito siempre se cifra durante la transferencia a trav&eacute;s de las redes.</p>
<p><br></p>
<p>Usted acepta no reproducir, duplicar, copiar, vender, revender ni aprovechar ninguna parte del Servicio, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a trav&eacute;s de la cual se presta el servicio, sin nuestro permiso expreso por escrito.</p>
<p>Los encabezados utilizados en este acuerdo se incluyen solo para facilitar la lectura y no limitar&aacute;n ni afectar&aacute;n los presentes T&eacute;rminos.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 3: EXACTITUD, TOTALIDAD Y CRONOLOG&Iacute;A DE LA INFORMACI&Oacute;N</em></strong></p>
<p>No nos responsabilizamos si la informaci&oacute;n disponible en este sitio no es precisa, completa o actualizada. El material presentado en este sitio se proporciona solo para informaci&oacute;n general y no se debe confiar en &eacute;l ni utilizarlo como la &uacute;nica base para tomar decisiones sin consultar fuentes de informaci&oacute;n principales, m&aacute;s precisas, m&aacute;s completas o m&aacute;s recientes. Al confiar en cualquier material de este sitio lo hace por su cuenta y riesgo.</p>
<p><br></p>
<p>Este sitio puede contener cierta informaci&oacute;n hist&oacute;rica. La informaci&oacute;n hist&oacute;rica, inevitablemente, no es actual y se proporciona &uacute;nicamente como referencia. Nos reservamos el derecho de modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligaci&oacute;n de actualizar ninguna informaci&oacute;n en nuestro sitio. Usted acepta que es su responsabilidad controlar los cambios en nuestro sitio.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 4: MODIFICACIONES AL SERVICIO Y PRECIOS</em></strong></p>
<p>Los precios de nuestros productos est&aacute;n sujetos a cambios sin previo aviso.</p>
<p>Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte o contenido del mismo) sin previo aviso en cualquier momento.</p>
<p>No seremos responsables ante usted ni ante ning&uacute;n tercero por ninguna modificaci&oacute;n, cambio de precio, suspensi&oacute;n o interrupci&oacute;n del Servicio.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 5: PRODUCTOS O SERVICIOS</em></strong></p>
<p>Ciertos productos o servicios pueden estar disponibles exclusivamente online a trav&eacute;s del sitio web. Estos productos o servicios pueden tener cantidades limitadas.</p>
<p><br></p>
<p>Nos reservamos el derecho, pero no estamos obligados, de limitar las ventas de nuestros productos o servicios a cualquier persona, regi&oacute;n geogr&aacute;fica o jurisdicci&oacute;n. Podemos ejercer este derecho caso por caso. Nos reservamos el derecho de limitar las cantidades de cualquier producto o servicio que ofrecemos. Todas las descripciones de los productos o los precios de los productos est&aacute;n sujetos a cambios en cualquier momento y sin previo aviso, a nuestra entera discreci&oacute;n. Nos reservamos el derecho de discontinuar cualquier producto en cualquier momento. Cualquier oferta de cualquier producto o servicio que se realice en este sitio no tiene validez donde dicho producto o servicio est&eacute; prohibido.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 6: EXACTITUD DE LA FACTURACI&Oacute;N Y DE LA INFORMACI&Oacute;N DE LA CUENTA</em></strong></p>
<p>Nos reservamos el derecho de rechazar cualquier suscripci&oacute;n que realice en nuestra plataforma. Podemos, a nuestro exclusivo criterio, limitar o cancelar las suscripciones por persona, por hogar o por suscripci&oacute;n . Estas restricciones pueden incluir suscripciones realizadas con la misma cuenta de cliente, la misma tarjeta de cr&eacute;dito o suscripciones que usen la misma direcci&oacute;n de facturaci&oacute;n o de env&iacute;o. En el caso de que realicemos un cambio o cancelemos un suscripci&oacute;n, intentaremos notificarle v&iacute;a correo electr&oacute;nico o la direcci&oacute;n de facturaci&oacute;n / n&uacute;mero de tel&eacute;fono proporcionados en el momento en que se realiz&oacute; el suscripci&oacute;n. Nos reservamos el derecho de limitar o prohibir los suscripciones que, a nuestra entera discreci&oacute;n, parezcan haber sido realizados por comerciantes, revendedores o distribuidores.</p>
<p>Usted acepta suministrar informaci&oacute;n completa y precisa de la suscripci&oacute;n y cuenta actual, para todas las suscripciones realizadas en nuestra plataforma. Usted acepta actualizar r&aacute;pidamente su cuenta y dem&aacute;s informaciones, entre ellas, su direcci&oacute;n de correo electr&oacute;nico, los n&uacute;meros de tarjeta de cr&eacute;dito y las fechas de vencimiento, para que podamos completar sus transacciones y contactarlo seg&uacute;n sea necesario.</p>
<p>Para obtener m&aacute;s informaci&oacute;n, consulte nuestra Pol&iacute;tica de devoluciones.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 7: HERRAMIENTAS OPCIONALES</em></strong></p>
<p>Podemos proporcionarle acceso a herramientas de terceros que no supervisamos, ni tenemos ning&uacute;n control sobre ellas, ni tampoco contribuimos.</p>
<p>Usted reconoce y acepta que brindamos acceso a dichas herramientas &quot;tal como se encuentran&quot; y &quot;seg&uacute;n disponibilidad&quot; sin garant&iacute;as, representaciones ni condiciones de ning&uacute;n tipo y sin ning&uacute;n tipo de respaldo. No tendremos ninguna responsabilidad como consecuencia del uso que haga de herramientas opcionales de terceros o en relaci&oacute;n a ellas.</p>
<p><br></p>
<p>Cualquier uso que haga de las herramientas opcionales ofrecidas a trav&eacute;s del sitio es por su cuenta y riesgo, y debe asegurarse de estar familiarizado con los t&eacute;rminos seg&uacute;n los cuales los proveedores externos relevantes suministran dichas herramientas y aprobarlos.</p>
<p>Tambi&eacute;n podemos, en el futuro, ofrecer nuevos servicios o funciones a trav&eacute;s del sitio web (incluido el lanzamiento de nuevas herramientas y recursos). Estas nuevas funciones o servicios tambi&eacute;n estar&aacute;n sujetos a los presentes T&eacute;rminos de servicio.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 8: ENLACES DE TERCEROS</em></strong></p>
<p>Algunos contenidos, productos y servicios disponibles a trav&eacute;s de nuestro Servicio pueden incluir recursos de terceros.</p>
<p>Los enlaces de terceros en este sitio pueden dirigirlo a p&aacute;ginas web de terceros que no est&aacute;n afiliados a nosotros. No somos responsables de examinar o evaluar el contenido o la exactitud, ni garantizamos ni asumiremos ninguna obligaci&oacute;n ni responsabilidad por los recursos o p&aacute;ginas web de terceros, ni por ning&uacute;n otro material, producto o servicio de terceros.</p>
<p><br></p>
<p>No somos responsables de ning&uacute;n da&ntilde;o o perjuicio relacionado con la compra o el uso de bienes, servicios, recursos, contenido o cualquier otra transacci&oacute;n realizada en conexi&oacute;n con sitios web de terceros. Revise cuidadosamente las pol&iacute;ticas y pr&aacute;cticas de terceros, y aseg&uacute;rese de comprenderlas antes de participar en cualquier transacci&oacute;n. Las quejas, reclamos, inquietudes o preguntas referentes a productos de terceros deben dirigirse a estos.</p>
<p><strong><em>SECCI&Oacute;N 9: COMENTARIOS DE LOS USUARIOS, OPINIONES Y OTRAS COMUNICACIONES</em></strong></p>
<p>Si, a petici&oacute;n nuestra, usted env&iacute;a ciertas comunicaciones espec&iacute;ficas (por ejemplo, participaciones en un concurso) o, sin una solicitud nuestra, env&iacute;a ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea online, por correo electr&oacute;nico, por correo postal, o de otro modo (denominado en lo sucesivo y de manera colectiva, &apos;comentarios&apos;), usted acepta que podemos, en cualquier momento, sin restricci&oacute;n: editar, copiar, publicar, distribuir, traducir y usar en cualquier medio cualquier comentario que usted nos env&iacute;e. No tenemos ni tendremos ninguna obligaci&oacute;n (1) de mantener ning&uacute;n comentario de manera confidencial; (2) pagar alguna compensaci&oacute;n por cualquier comentario; o (3) responder a cualquier comentario.</p>
<p>Podemos, pero no tenemos la obligaci&oacute;n de monitorear, editar o eliminar contenido que a nuestra entera discreci&oacute;n determinemos que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornogr&aacute;fico, obsceno u objetable, o que infrinja la propiedad intelectual de cualquiera de las partes o de los presentes T&eacute;rminos del servicio.</p>
<p>Usted acepta que sus comentarios no infringir&aacute;n ning&uacute;n derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otro derecho personal o de propiedad. Adem&aacute;s, acepta que sus comentarios no contendr&aacute;n material difamatorio, ileg&iacute;timo, abusivo u obsceno, ni contendr&aacute;n ning&uacute;n virus inform&aacute;tico ni otro software da&ntilde;ino que pueda afectar de cualquier manera el funcionamiento del Servicio o de cualquier sitio web relacionado. No puede utilizar una direcci&oacute;n de correo electr&oacute;nico falsa, simular ser otra persona o enga&ntilde;arnos o enga&ntilde;ar a terceros sobre el origen de los comentarios. Usted es el &uacute;nico responsable de los comentarios que realice y de su exactitud. No asumimos ninguna responsabilidad ni ninguna obligaci&oacute;n por los comentarios publicados por usted o por un tercero.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 10: INFORMACI&Oacute;N PERSONAL</em></strong></p>
<p>El env&iacute;o de la informaci&oacute;n personal que haga a trav&eacute;s de la plataforma se rige por nuestra Pol&iacute;tica de privacidad. Para ver nuestra Pol&iacute;tica de privacidad.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 11: ERRORES, INEXACTITUDES Y OMISIONES</em></strong></p>
<p>Puede haber informaci&oacute;n en nuestro sitio o en el Servicio que, ocasionalmente, contenga errores tipogr&aacute;ficos, inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios, promociones, ofertas, cargos por env&iacute;o de productos, tiempos de tr&aacute;nsito y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisi&oacute;n, de cambiar o actualizar informaci&oacute;n, o cancelar suscripci&oacute;n si alguna informaci&oacute;n en el Servicio o en cualquier p&aacute;gina web relacionada es inexacta en cualquier momento sin previo aviso (incluso despu&eacute;s de haber concretado su suscripci&oacute;n).</p>
<p><br></p>
<p>No asumimos ninguna obligaci&oacute;n de actualizar, modificar o aclarar la informaci&oacute;n en el Servicio o en cualquier sitio web relacionado, incluyendo de manera enunciativa pero no limitativa, la informaci&oacute;n de precios, excepto cuando lo exija la ley. Ninguna actualizaci&oacute;n especificada o fecha de actualizaci&oacute;n aplicada en el Servicio o en cualquier sitio web relacionado debe tomar para indicar que toda la informaci&oacute;n en el Servicio o en cualquier sitio web relacionado se modific&oacute; o actualiz&oacute;.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 12: USOS PROHIBIDOS</em></strong></p>
<p>Adem&aacute;s de las prohibiciones establecidas en los T&eacute;rminos del servicio, se le proh&iacute;be utilizar el sitio o su contenido (a) para cualquier prop&oacute;sito ilegal; (b) para solicitar a otros que realicen o participen en cualquier acto ilegal; (c) para infringir cualquier reglamento, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros; (e) acosar, abusar, insultar, da&ntilde;ar, difamar, calumniar, denigrar, intimidar o discriminar por motivos de g&eacute;nero, orientaci&oacute;n sexual, religi&oacute;n, etnia, raza, edad, nacionalidad o discapacidad; (f) enviar informaci&oacute;n falsa o enga&ntilde;osa;</p>
<p>(g) cargar o transmitir virus o cualquier otro tipo de c&oacute;digo da&ntilde;ino que afecte o pueda afectar a la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, de otros sitios web o de Internet; (h) recopilar o rastrear la informaci&oacute;n personal de otros; (i) enviar correo no deseado, phishing, pharm, pretexto, spider, rastrear o extraer; (j) para cualquier prop&oacute;sito obsceno o inmoral; o (k) para interferir o eludir las funciones de seguridad del Servicio o de cualquier sitio web relacionado, o de otros sitios web o de Internet. Nos reservamos el derecho de dar por terminado su uso del Servicio o de cualquier sitio web relacionado por infringir cualquiera de los usos prohibidos.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 13: DESCARGO DE RESPONSABILIDAD DE GARANT&Iacute;AS; LIMITACI&Oacute;N DE RESPONSABILIDAD</em></strong></p>
<p>No garantizamos, representamos ni aseguramos que el uso que haga de nuestro servicio ser&aacute; sin interrupciones, oportuno, seguro o sin errores.</p>
<p>No garantizamos que los resultados que se puedan obtener del uso del servicio sean exactos o confiables.</p>
<p><br></p>
<p>Usted acepta que peri&oacute;dicamente, podamos eliminar el servicio por lapsos de tiempo indefinidos o cancelar el servicio en cualquier momento, sin notificarle.</p>
<p>Usted acepta expresamente que su uso del servicio o la imposibilidad de utilizarlo corre por su riesgo. El servicio y todos los productos y servicios que se le entregan a trav&eacute;s del servicio (salvo que as&iacute; lo especifiquemos nosotros) se ofrecen &quot;tal como est&aacute;n&quot; y &quot;seg&uacute;n disponibilidad&quot; para su uso, sin ninguna representaci&oacute;n, garant&iacute;a o condici&oacute;n de ning&uacute;n tipo, ya sea expresa o impl&iacute;cita, entre las que se incluyen todas las garant&iacute;as impl&iacute;citas o condiciones de comerciabilidad, calidad comercial, idoneidad para un prop&oacute;sito particular, durabilidad, t&iacute;tulo y no violaci&oacute;n.</p>
<p>En ning&uacute;n caso BenBoot, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, pasantes, proveedores, proveedores de servicios o licenciantes ser&aacute;n responsables de cualquier lesi&oacute;n, p&eacute;rdida, reclamo o cualquier da&ntilde;o directo, indirecto, incidental, punitivo, especial o consecuente de cualquier tipo, incluyendo de manera enunciativa m&aacute;s no limitativa; la p&eacute;rdida de beneficios, p&eacute;rdida de ingresos, p&eacute;rdida de ahorros, p&eacute;rdida de datos, costos de reemplazo o da&ntilde;os similares, ya sea por contrato, perjuicio (incluida la negligencia), responsabilidad estricta o de otro tipo, que surjan del uso que haga de cualquiera de los servicios o de cualquier producto adquirido por medio del servicio, o para cualquier otro reclamo relacionado de alguna manera con su uso del servicio o de cualquier producto, incluidos, entre otros, cualquier error u omisi&oacute;n en cualquier contenido, o cualquier p&eacute;rdida o da&ntilde;o de cualquier tipo en el que se haya incurrido como resultado del uso del servicio o de cualquier contenido (o producto) publicado, transmitido o puesto a disposici&oacute;n a trav&eacute;s del servicio, incluso si se informa de su posibilidad.</p>
<p>Debido a que algunos estados o jurisdicciones no permiten la exclusi&oacute;n o la limitaci&oacute;n de la responsabilidad por da&ntilde;os consecuentes o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitar&aacute; a la extensi&oacute;n m&aacute;xima de lo permitido por la ley.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 14: INDEMNIZACI&Oacute;N</em></strong></p>
<p>Usted acepta indemnizar, defender y mantener indemne a BenBoot y a nuestra empresa matriz, subsidiarias, afiliadas, asociados, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, de cualquier reclamo o demanda, incluidos los honorarios razonables de abogados, en los que un tercero haya incurrido debido a su incumplimiento de los presentes T&eacute;rminos del servicio o de los documentos que incorporan como referencia o que surjan por su incumplimiento de los mismos, o por la violaci&oacute;n de cualquier ley o derechos de un tercero que haga.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 15: DIVISIBILIDAD</em></strong></p>
<p>En caso de que se determine que alguna disposici&oacute;n de los presentes T&eacute;rminos del servicio sea ilegal, nula o inaplicable, dicha disposici&oacute;n ser&aacute;, no obstante, ejecutable en la medida en que lo permita la legislaci&oacute;n aplicable, y la parte inaplicable se considerar&aacute; separada de los presentes T&eacute;rminos del servicio, sin que dicha determinaci&oacute;n afecte a la validez y aplicabilidad de las dem&aacute;s disposiciones.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 16: RESCISI&Oacute;N</em></strong></p>
<p>Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de rescisi&oacute;n seguir&aacute;n vigentes despu&eacute;s de la rescisi&oacute;n de este contrato a todos los efectos.</p>
<p>Estos T&eacute;rminos del servicio se encuentran vigentes a menos que usted o nosotros los prescindamos. Puede rescindir los presentes T&eacute;rminos del servicio en cualquier momento al notificarnos que ya no desea utilizar nuestros Servicios o cuando cese de utilizar nuestro sitio.</p>
<p>Si a nuestro juicio usted incumple, o sospechamos que ha incumplido con cualquier t&eacute;rmino o disposici&oacute;n de los presentes T&eacute;rminos del servicio, podemos rescindir igualmente este acuerdo en cualquier momento sin previo aviso y usted seguir&aacute; siendo responsable de todos los importes adeudados, hasta la fecha de rescisi&oacute;n inclusive; y/o en consecuencia podemos denegarle el acceso a nuestros Servicios (o a parte de ellos).</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 17: ACUERDO COMPLETO</em></strong></p>
<p>El hecho de que no ejerzamos o hagamos valer alg&uacute;n derecho o disposici&oacute;n de los presentes T&eacute;rminos del Servicio no constituir&aacute; una renuncia a dicho derecho o disposici&oacute;n.</p>
<p><br></p>
<p>Estos T&eacute;rminos de servicio y cualquier pol&iacute;tica o regla de funcionamiento que hayamos publicado en este sitio o con respecto al Servicio constituye el acuerdo y el entendimiento completo entre usted y nosotros, y rigen su uso del Servicio, sustituyendo a cualquier acuerdo, comunicaci&oacute;n o propuesta anterior o contempor&aacute;nea, ya sea oral o escrita, entre usted y nosotros (incluyendo de manera enunciativa m&aacute;s no limitativa, las versiones anteriores de los T&eacute;rminos del servicio).</p>
<p>Cualquier ambig&uuml;edad en la interpretaci&oacute;n de los presentes T&eacute;rminos del servicio no se interpretar&aacute; en contra de la parte redactora.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 18: LEY APLICABLE</em></strong></p>
<p>Los presentes T&eacute;rminos del servicio y cualquier acuerdo por separado por el cual le proporcionemos Servicios se regir&aacute;n e interpretar&aacute;n de acuerdo con las leyes de 25 de Mayo 713, Montevideo, Montevideo, 11000, Uruguay.</p>
<p><br></p>
<p><strong><em>SECCI&Oacute;N 19: CAMBIOS EN LOS T&Eacute;RMINOS DEL SERVICIO</em></strong></p>
<p>Puede revisar la versi&oacute;n m&aacute;s reciente de los T&eacute;rminos del servicio en cualquier momento en esta p&aacute;gina.</p>
<p>Nos reservamos el derecho, a nuestra entera discreci&oacute;n, de actualizar, cambiar o sustituir cualquier parte de los presentes T&eacute;rminos del servicio mediante la publicaci&oacute;n de actualizaciones y cambios en nuestro sitio web. Es su responsabilidad consultar nuestro sitio web peri&oacute;dicamente para ver los cambios. El uso que haga de nuestra p&aacute;gina web o del Servicio o su acceso a cualquiera de estos de forma continua despu&eacute;s de la publicaci&oacute;n de cualquier cambio en los presentes T&eacute;rminos del servicio, constituye la aceptaci&oacute;n de dichos cambios.</p>
<p><br></p>
<p><strong><em>SECTION 20: INFORMACI&Oacute;N DE CONTACTO</em></strong></p>
<p>Las preguntas sobre los T&eacute;rminos del servicio se deben enviar a&nbsp;<a href="mailto:info@benboot.com" rel="noopener noreferrer" target="_blank">info@benboot.com</a>.</p>
<p><br></p>
<p><br></p>`

export const terms = `<p><strong>GENERALIDADES</strong></p>
<p><br></p>
<p>BenBoot gestiona este sitio web. En todo el sitio, los t&eacute;rminos &quot;nosotros&quot;, &quot;nos&quot; y &quot;nuestro&quot; se refieren en lo sucesivo a BenBoot. BenBoot ofrece esta p&aacute;gina web, incluida toda la informaci&oacute;n, las herramientas y los servicios que se ponen en este sitio a disposici&oacute;n suya, el usuario, siempre y cuando acepte la totalidad de los t&eacute;rminos, condiciones, pol&iacute;ticas y avisos contemplados aqu&iacute;.</p>
<p><br></p>
<p>Al visitar nuestro sitio y/o comprarnos algo, usted interact&uacute;a con nuestro &quot;Servicio&quot; y reconoce como vinculantes los siguientes t&eacute;rminos y condiciones (denominados en lo sucesivo &quot;T&eacute;rminos del servicio&quot;, &quot;T&eacute;rminos&quot;), incluidos aquellos t&eacute;rminos y condiciones adicionales y las pol&iacute;ticas que se mencionan aqu&iacute; y/o disponibles por medio de hiperv&iacute;nculo. Estos T&eacute;rminos del servicio se aplican a todos los usuarios del sitio, incluyendo de manera enunciativa m&aacute;s no limitativa los usuarios que son navegadores, proveedores, clientes, comerciantes y/o que aporten contenido.</p>
<p>Lea estos T&eacute;rminos del servicio detenidamente antes de acceder o utilizar nuestra p&aacute;gina web. Al acceder o utilizar cualquier parte del sitio, usted acepta estos T&eacute;rminos del servicio. Si no acepta la totalidad de los t&eacute;rminos y condiciones de este acuerdo, no podr&aacute; acceder al sitio web ni utilizar ning&uacute;n servicio. Si estos T&eacute;rminos del servicio se considerasen una oferta, la aceptaci&oacute;n se limita expresamente a los presentes T&eacute;rminos del servicio.</p>
<p><br></p>
<p>Las nuevas funciones o herramientas que se agreguen a la plataforma actual tambi&eacute;n estar&aacute;n sujetas a los T&eacute;rminos del servicio. Puede revisar la versi&oacute;n m&aacute;s reciente de los T&eacute;rminos del servicio en cualquier momento en esta p&aacute;gina. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los presentes T&eacute;rminos del servicio mediante la publicaci&oacute;n de actualizaciones o cambios en nuestra p&aacute;gina web. Es su responsabilidad revisar esta p&aacute;gina peri&oacute;dicamente para ver los cambios. Su uso de la p&aacute;gina web o el acceso a ella de forma continuada despu&eacute;s de la publicaci&oacute;n de cualquier cambio constituye la aceptaci&oacute;n de dichos cambios.</p>
<p>Nuestra plataforma est&aacute; alojada en Amazon AWS y Heroku.</p>
<p><br></p>
<p><br></p>
<p><strong>SECCI&Oacute;N 1: T&Eacute;RMINOS DE LA PLATAFORMA</strong></p>
<p>Al aceptar los presentes T&eacute;rminos del servicio, usted declara que tiene la mayor&iacute;a de edad en su estado o provincia de residencia, o que es mayor de edad en su estado o provincia de residencia y que nos ha dado su consentimiento para permitir que cualquiera de las personas menores que dependen de usted utilice este sitio.</p>
<p><br></p>
<p>No puede utilizar nuestros productos para ning&uacute;n fin ilegal o no autorizado ni puede, al hacer uso del Servicio, infringir las leyes de su jurisdicci&oacute;n (incluyendo de manera enunciativa m&aacute;s no limitativa, las leyes de derechos de autor).</p>
<p>No transmitir&aacute; ning&uacute;n gusano o virus inform&aacute;ticos ni ning&uacute;n c&oacute;digo de naturaleza destructiva.</p>
<p>El incumplimiento o violaci&oacute;n de cualquiera de los T&eacute;rminos dar&aacute; como resultado la rescisi&oacute;n inmediata de sus Servicios.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 2: CONDICIONES GENERALES</strong></p>
<p>Nos reservamos el derecho de rechazar el servicio a cualquier persona, por cualquier motivo, en cualquier momento.</p>
<p><br></p>
<p>Usted comprende que su contenido (sin incluir la informaci&oacute;n de la tarjeta de cr&eacute;dito) puede transferirse sin cifrar e implicar (a) transmisiones en varias redes; y (b) cambios para adaptarse a los requisitos t&eacute;cnicos de conexi&oacute;n de redes o dispositivos y cumplir con ellos. La informaci&oacute;n de la tarjeta de cr&eacute;dito siempre se cifra durante la transferencia a trav&eacute;s de las redes.</p>
<p><br></p>
<p>Usted acepta no reproducir, duplicar, copiar, vender, revender ni aprovechar ninguna parte del Servicio, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a trav&eacute;s de la cual se presta el servicio, sin nuestro permiso expreso por escrito.</p>
<p>Los encabezados utilizados en este acuerdo se incluyen solo para facilitar la lectura y no limitar&aacute;n ni afectar&aacute;n los presentes T&eacute;rminos.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 3: EXACTITUD, TOTALIDAD Y CRONOLOG&Iacute;A DE LA INFORMACI&Oacute;N</strong></p>
<p>No nos responsabilizamos si la informaci&oacute;n disponible en este sitio no es precisa, completa o actualizada. El material presentado en este sitio se proporciona solo para informaci&oacute;n general y no se debe confiar en &eacute;l ni utilizarlo como la &uacute;nica base para tomar decisiones sin consultar fuentes de informaci&oacute;n principales, m&aacute;s precisas, m&aacute;s completas o m&aacute;s recientes. Al confiar en cualquier material de este sitio lo hace por su cuenta y riesgo.</p>
<p><br></p>
<p>Este sitio puede contener cierta informaci&oacute;n hist&oacute;rica. La informaci&oacute;n hist&oacute;rica, inevitablemente, no es actual y se proporciona &uacute;nicamente como referencia. Nos reservamos el derecho de modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligaci&oacute;n de actualizar ninguna informaci&oacute;n en nuestro sitio. Usted acepta que es su responsabilidad controlar los cambios en nuestro sitio.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 4: MODIFICACIONES AL SERVICIO Y PRECIOS</strong></p>
<p>Los precios de nuestros productos est&aacute;n sujetos a cambios sin previo aviso.</p>
<p>Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte o contenido del mismo) sin previo aviso en cualquier momento.</p>
<p>No seremos responsables ante usted ni ante ning&uacute;n tercero por ninguna modificaci&oacute;n, cambio de precio, suspensi&oacute;n o interrupci&oacute;n del Servicio.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 5: PRODUCTOS O SERVICIOS</strong></p>
<p>Ciertos productos o servicios pueden estar disponibles exclusivamente online a trav&eacute;s del sitio web. Estos productos o servicios pueden tener cantidades limitadas.</p>
<p><br></p>
<p>Nos reservamos el derecho, pero no estamos obligados, de limitar las ventas de nuestros productos o servicios a cualquier persona, regi&oacute;n geogr&aacute;fica o jurisdicci&oacute;n. Podemos ejercer este derecho caso por caso. Nos reservamos el derecho de limitar las cantidades de cualquier producto o servicio que ofrecemos. Todas las descripciones de los productos o los precios de los productos est&aacute;n sujetos a cambios en cualquier momento y sin previo aviso, a nuestra entera discreci&oacute;n. Nos reservamos el derecho de discontinuar cualquier producto en cualquier momento. Cualquier oferta de cualquier producto o servicio que se realice en este sitio no tiene validez donde dicho producto o servicio est&eacute; prohibido.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 6: EXACTITUD DE LA FACTURACI&Oacute;N Y DE LA INFORMACI&Oacute;N DE LA CUENTA</strong></p>
<p>Nos reservamos el derecho de rechazar cualquier suscripci&oacute;n que realice en nuestra plataforma. Podemos, a nuestro exclusivo criterio, limitar o cancelar las suscripciones por persona, por hogar o por suscripci&oacute;n . Estas restricciones pueden incluir suscripciones realizadas con la misma cuenta de cliente, la misma tarjeta de cr&eacute;dito o suscripciones que usen la misma direcci&oacute;n de facturaci&oacute;n o de env&iacute;o. En el caso de que realicemos un cambio o cancelemos un suscripci&oacute;n, intentaremos notificarle v&iacute;a correo electr&oacute;nico o la direcci&oacute;n de facturaci&oacute;n / n&uacute;mero de tel&eacute;fono proporcionados en el momento en que se realiz&oacute; el suscripci&oacute;n. Nos reservamos el derecho de limitar o prohibir los suscripciones que, a nuestra entera discreci&oacute;n, parezcan haber sido realizados por comerciantes, revendedores o distribuidores.</p>
<p>Usted acepta suministrar informaci&oacute;n completa y precisa de la suscripci&oacute;n y cuenta actual, para todas las suscripciones realizadas en nuestra plataforma. Usted acepta actualizar r&aacute;pidamente su cuenta y dem&aacute;s informaciones, entre ellas, su direcci&oacute;n de correo electr&oacute;nico, los n&uacute;meros de tarjeta de cr&eacute;dito y las fechas de vencimiento, para que podamos completar sus transacciones y contactarlo seg&uacute;n sea necesario.</p>
<p>Para obtener m&aacute;s informaci&oacute;n, consulte nuestra Pol&iacute;tica de devoluciones.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 7: HERRAMIENTAS OPCIONALES</strong></p>
<p>Podemos proporcionarle acceso a herramientas de terceros que no supervisamos, ni tenemos ning&uacute;n control sobre ellas, ni tampoco contribuimos.</p>
<p>Usted reconoce y acepta que brindamos acceso a dichas herramientas &quot;tal como se encuentran&quot; y &quot;seg&uacute;n disponibilidad&quot; sin garant&iacute;as, representaciones ni condiciones de ning&uacute;n tipo y sin ning&uacute;n tipo de respaldo. No tendremos ninguna responsabilidad como consecuencia del uso que haga de herramientas opcionales de terceros o en relaci&oacute;n a ellas.</p>
<p><br></p>
<p>Cualquier uso que haga de las herramientas opcionales ofrecidas a trav&eacute;s del sitio es por su cuenta y riesgo, y debe asegurarse de estar familiarizado con los t&eacute;rminos seg&uacute;n los cuales los proveedores externos relevantes suministran dichas herramientas y aprobarlos.</p>
<p>Tambi&eacute;n podemos, en el futuro, ofrecer nuevos servicios o funciones a trav&eacute;s del sitio web (incluido el lanzamiento de nuevas herramientas y recursos). Estas nuevas funciones o servicios tambi&eacute;n estar&aacute;n sujetos a los presentes T&eacute;rminos de servicio.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 8: ENLACES DE TERCEROS</strong></p>
<p>Algunos contenidos, productos y servicios disponibles a trav&eacute;s de nuestro Servicio pueden incluir recursos de terceros.</p>
<p>Los enlaces de terceros en este sitio pueden dirigirlo a p&aacute;ginas web de terceros que no est&aacute;n afiliados a nosotros. No somos responsables de examinar o evaluar el contenido o la exactitud, ni garantizamos ni asumiremos ninguna obligaci&oacute;n ni responsabilidad por los recursos o p&aacute;ginas web de terceros, ni por ning&uacute;n otro material, producto o servicio de terceros.</p>
<p><br></p>
<p>No somos responsables de ning&uacute;n da&ntilde;o o perjuicio relacionado con la compra o el uso de bienes, servicios, recursos, contenido o cualquier otra transacci&oacute;n realizada en conexi&oacute;n con sitios web de terceros. Revise cuidadosamente las pol&iacute;ticas y pr&aacute;cticas de terceros, y aseg&uacute;rese de comprenderlas antes de participar en cualquier transacci&oacute;n. Las quejas, reclamos, inquietudes o preguntas referentes a productos de terceros deben dirigirse a estos.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 9: COMENTARIOS DE LOS USUARIOS, OPINIONES Y OTRAS COMUNICACIONES</strong></p>
<p>Si, a petici&oacute;n nuestra, usted env&iacute;a ciertas comunicaciones espec&iacute;ficas (por ejemplo, participaciones en un concurso) o, sin una solicitud nuestra, env&iacute;a ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea online, por correo electr&oacute;nico, por correo postal, o de otro modo (denominado en lo sucesivo y de manera colectiva, &apos;comentarios&apos;), usted acepta que podemos, en cualquier momento, sin restricci&oacute;n: editar, copiar, publicar, distribuir, traducir y usar en cualquier medio cualquier comentario que usted nos env&iacute;e. No tenemos ni tendremos ninguna obligaci&oacute;n (1) de mantener ning&uacute;n comentario de manera confidencial; (2) pagar alguna compensaci&oacute;n por cualquier comentario; o (3) responder a cualquier comentario.</p>
<p><br></p>
<p>Podemos, pero no tenemos la obligaci&oacute;n de monitorear, editar o eliminar contenido que a nuestra entera discreci&oacute;n determinemos que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornogr&aacute;fico, obsceno u objetable, o que infrinja la propiedad intelectual de cualquiera de las partes o de los presentes T&eacute;rminos del servicio.</p>
<p><br></p>
<p>Usted acepta que sus comentarios no infringir&aacute;n ning&uacute;n derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otro derecho personal o de propiedad. Adem&aacute;s, acepta que sus comentarios no contendr&aacute;n material difamatorio, ileg&iacute;timo, abusivo u obsceno, ni contendr&aacute;n ning&uacute;n virus inform&aacute;tico ni otro software da&ntilde;ino que pueda afectar de cualquier manera el funcionamiento del Servicio o de cualquier sitio web relacionado. No puede utilizar una direcci&oacute;n de correo electr&oacute;nico falsa, simular ser otra persona o enga&ntilde;arnos o enga&ntilde;ar a terceros sobre el origen de los comentarios. Usted es el &uacute;nico responsable de los comentarios que realice y de su exactitud. No asumimos ninguna responsabilidad ni ninguna obligaci&oacute;n por los comentarios publicados por usted o por un tercero.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 10: INFORMACI&Oacute;N PERSONAL</strong></p>
<p>El env&iacute;o de la informaci&oacute;n personal que haga a trav&eacute;s de la plataforma se rige por nuestra Pol&iacute;tica de privacidad. Para ver nuestra Pol&iacute;tica de privacidad.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 11: ERRORES, INEXACTITUDES Y OMISIONES</strong></p>
<p>Puede haber informaci&oacute;n en nuestro sitio o en el Servicio que, ocasionalmente, contenga errores tipogr&aacute;ficos, inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios, promociones, ofertas, cargos por env&iacute;o de productos, tiempos de tr&aacute;nsito y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisi&oacute;n, de cambiar o actualizar informaci&oacute;n, o cancelar suscripci&oacute;n si alguna informaci&oacute;n en el Servicio o en cualquier p&aacute;gina web relacionada es inexacta en cualquier momento sin previo aviso (incluso despu&eacute;s de haber concretado su suscripci&oacute;n).</p>
<p><br></p>
<p>No asumimos ninguna obligaci&oacute;n de actualizar, modificar o aclarar la informaci&oacute;n en el Servicio o en cualquier sitio web relacionado, incluyendo de manera enunciativa pero no limitativa, la informaci&oacute;n de precios, excepto cuando lo exija la ley. Ninguna actualizaci&oacute;n especificada o fecha de actualizaci&oacute;n aplicada en el Servicio o en cualquier sitio web relacionado debe tomar para indicar que toda la informaci&oacute;n en el Servicio o en cualquier sitio web relacionado se modific&oacute; o actualiz&oacute;.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 12: USOS PROHIBIDOS</strong></p>
<p>Adem&aacute;s de las prohibiciones establecidas en los T&eacute;rminos del servicio, se le proh&iacute;be utilizar el sitio o su contenido (a) para cualquier prop&oacute;sito ilegal; (b) para solicitar a otros que realicen o participen en cualquier acto ilegal; (c) para infringir cualquier reglamento, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros; (e) acosar, abusar, insultar, da&ntilde;ar, difamar, calumniar, denigrar, intimidar o discriminar por motivos de g&eacute;nero, orientaci&oacute;n sexual, religi&oacute;n, etnia, raza, edad, nacionalidad o discapacidad; (f) enviar informaci&oacute;n falsa o enga&ntilde;osa;</p>
<p>(g) cargar o transmitir virus o cualquier otro tipo de c&oacute;digo da&ntilde;ino que afecte o pueda afectar a la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, de otros sitios web o de Internet; (h) recopilar o rastrear la informaci&oacute;n personal de otros; (i) enviar correo no deseado, phishing, pharm, pretexto, spider, rastrear o extraer; (j) para cualquier prop&oacute;sito obsceno o inmoral; o (k) para interferir o eludir las funciones de seguridad del Servicio o de cualquier sitio web relacionado, o de otros sitios web o de Internet. Nos reservamos el derecho de dar por terminado su uso del Servicio o de cualquier sitio web relacionado por infringir cualquiera de los usos prohibidos.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 13: DESCARGO DE RESPONSABILIDAD DE GARANT&Iacute;AS; LIMITACI&Oacute;N DE RESPONSABILIDAD</strong></p>
<p><br></p>
<p>No garantizamos, representamos ni aseguramos que el uso que haga de nuestro servicio ser&aacute; sin interrupciones, oportuno, seguro o sin errores.</p>
<p>No garantizamos que los resultados que se puedan obtener del uso del servicio sean exactos o confiables.</p>
<p><br></p>
<p>Usted acepta que peri&oacute;dicamente, podamos eliminar el servicio por lapsos de tiempo indefinidos o cancelar el servicio en cualquier momento, sin notificarle.</p>
<p>Usted acepta expresamente que su uso del servicio o la imposibilidad de utilizarlo corre por su riesgo. El servicio y todos los productos y servicios que se le entregan a trav&eacute;s del servicio (salvo que as&iacute; lo especifiquemos nosotros) se ofrecen &quot;tal como est&aacute;n&quot; y &quot;seg&uacute;n disponibilidad&quot; para su uso, sin ninguna representaci&oacute;n, garant&iacute;a o condici&oacute;n de ning&uacute;n tipo, ya sea expresa o impl&iacute;cita, entre las que se incluyen todas las garant&iacute;as impl&iacute;citas o condiciones de comerciabilidad, calidad comercial, idoneidad para un prop&oacute;sito particular, durabilidad, t&iacute;tulo y no violaci&oacute;n.</p>
<p><br></p>
<p>En ning&uacute;n caso BenBoot, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, pasantes, proveedores, proveedores de servicios o licenciantes ser&aacute;n responsables de cualquier lesi&oacute;n, p&eacute;rdida, reclamo o cualquier da&ntilde;o directo, indirecto, incidental, punitivo, especial o consecuente de cualquier tipo, incluyendo de manera enunciativa m&aacute;s no limitativa; la p&eacute;rdida de beneficios, p&eacute;rdida de ingresos, p&eacute;rdida de ahorros, p&eacute;rdida de datos, costos de reemplazo o da&ntilde;os similares, ya sea por contrato, perjuicio (incluida la negligencia), responsabilidad estricta o de otro tipo, que surjan del uso que haga de cualquiera de los servicios o de cualquier producto adquirido por medio del servicio, o para cualquier otro reclamo relacionado de alguna manera con su uso del servicio o de cualquier producto, incluidos, entre otros, cualquier error u omisi&oacute;n en cualquier contenido, o cualquier p&eacute;rdida o da&ntilde;o de cualquier tipo en el que se haya incurrido como resultado del uso del servicio o de cualquier contenido (o producto) publicado, transmitido o puesto a disposici&oacute;n a trav&eacute;s del servicio, incluso si se informa de su posibilidad.</p>
<p><br></p>
<p>Debido a que algunos estados o jurisdicciones no permiten la exclusi&oacute;n o la limitaci&oacute;n de la responsabilidad por da&ntilde;os consecuentes o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitar&aacute; a la extensi&oacute;n m&aacute;xima de lo permitido por la ley.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 14: INDEMNIZACI&Oacute;N</strong></p>
<p>Usted acepta indemnizar, defender y mantener indemne a BenBoot y a nuestra empresa matriz, subsidiarias, afiliadas, asociados, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, de cualquier reclamo o demanda, incluidos los honorarios razonables de abogados, en los que un tercero haya incurrido debido a su incumplimiento de los presentes T&eacute;rminos del servicio o de los documentos que incorporan como referencia o que surjan por su incumplimiento de los mismos, o por la violaci&oacute;n de cualquier ley o derechos de un tercero que haga.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 15: DIVISIBILIDAD</strong></p>
<p>En caso de que se determine que alguna disposici&oacute;n de los presentes T&eacute;rminos del servicio sea ilegal, nula o inaplicable, dicha disposici&oacute;n ser&aacute;, no obstante, ejecutable en la medida en que lo permita la legislaci&oacute;n aplicable, y la parte inaplicable se considerar&aacute; separada de los presentes T&eacute;rminos del servicio, sin que dicha determinaci&oacute;n afecte a la validez y aplicabilidad de las dem&aacute;s disposiciones.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 16: RESCISI&Oacute;N</strong></p>
<p>Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de rescisi&oacute;n seguir&aacute;n vigentes despu&eacute;s de la rescisi&oacute;n de este contrato a todos los efectos.</p>
<p>Estos T&eacute;rminos del servicio se encuentran vigentes a menos que usted o nosotros los prescindamos. Puede rescindir los presentes T&eacute;rminos del servicio en cualquier momento al notificarnos que ya no desea utilizar nuestros Servicios o cuando cese de utilizar nuestro sitio.</p>
<p>Si a nuestro juicio usted incumple, o sospechamos que ha incumplido con cualquier t&eacute;rmino o disposici&oacute;n de los presentes T&eacute;rminos del servicio, podemos rescindir igualmente este acuerdo en cualquier momento sin previo aviso y usted seguir&aacute; siendo responsable de todos los importes adeudados, hasta la fecha de rescisi&oacute;n inclusive; y/o en consecuencia podemos denegarle el acceso a nuestros Servicios (o a parte de ellos).</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 17: ACUERDO COMPLETO</strong></p>
<p>El hecho de que no ejerzamos o hagamos valer alg&uacute;n derecho o disposici&oacute;n de los presentes T&eacute;rminos del Servicio no constituir&aacute; una renuncia a dicho derecho o disposici&oacute;n.</p>
<p><br></p>
<p>Estos T&eacute;rminos de servicio y cualquier pol&iacute;tica o regla de funcionamiento que hayamos publicado en este sitio o con respecto al Servicio constituye el acuerdo y el entendimiento completo entre usted y nosotros, y rigen su uso del Servicio, sustituyendo a cualquier acuerdo, comunicaci&oacute;n o propuesta anterior o contempor&aacute;nea, ya sea oral o escrita, entre usted y nosotros (incluyendo de manera enunciativa m&aacute;s no limitativa, las versiones anteriores de los T&eacute;rminos del servicio).</p>
<p>Cualquier ambig&uuml;edad en la interpretaci&oacute;n de los presentes T&eacute;rminos del servicio no se interpretar&aacute; en contra de la parte redactora.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 18: LEY APLICABLE</strong></p>
<p>Los presentes T&eacute;rminos del servicio y cualquier acuerdo por separado por el cual le proporcionemos Servicios se regir&aacute;n e interpretar&aacute;n de acuerdo con las leyes de 25 de Mayo 713, Montevideo, Montevideo, 11000, Uruguay.</p>
<p><br></p>
<p><strong>SECCI&Oacute;N 19: CAMBIOS EN LOS T&Eacute;RMINOS DEL SERVICIO</strong></p>
<p>Puede revisar la versi&oacute;n m&aacute;s reciente de los T&eacute;rminos del servicio en cualquier momento en esta p&aacute;gina.</p>
<p>Nos reservamos el derecho, a nuestra entera discreci&oacute;n, de actualizar, cambiar o sustituir cualquier parte de los presentes T&eacute;rminos del servicio mediante la publicaci&oacute;n de actualizaciones y cambios en nuestro sitio web. Es su responsabilidad consultar nuestro sitio web peri&oacute;dicamente para ver los cambios. El uso que haga de nuestra p&aacute;gina web o del Servicio o su acceso a cualquiera de estos de forma continua despu&eacute;s de la publicaci&oacute;n de cualquier cambio en los presentes T&eacute;rminos del servicio, constituye la aceptaci&oacute;n de dichos cambios.</p>
<p><br></p>
<p><strong>SECTION 20: INFORMACI&Oacute;N DE CONTACTO</strong></p>
<p>Las preguntas sobre los T&eacute;rminos del servicio se deben enviar a info@benboot.com.</p>`

// @mui
import { styled } from '@mui/material/styles';
import { Container, Grid, Button } from '@mui/material';

import { BankingInviteFriends } from '../@dashboard/general/banking';
import { AppWelcome } from '../@dashboard/general/app';
import { SeoIllustration } from '../../assets';
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));


export default function HomeHugePackElements() {

  const handleOnClick = () => {
    window.location.href = `${PATH_PAGE.contact}`;
  };

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <BankingInviteFriends
              price={` `}
              title={`Eres Agente \n inmobiliario?`}
              description="¡Te invitamos a que te unas a nuestra comunidad usando el CRM mas potente del mercado!"
              img="/assets/illustrations/illustration_invite.png"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ mt: 10 }}>
            <AppWelcome
              title={`Tienes una inmobiliaria?`}
              description="Si tienes una inmobiliaria y cuentas con tu propio sistema CRM, tambien puedes publicar tus propiedades en BenBoot Portal."
              img={
                <SeoIllustration
                  sx={{
                    p: 3,
                    width: 360,
                    margin: { xs: 'auto', md: 'inherit' },
                  }}
                />
              }
              action={<Button variant="contained" onClick={handleOnClick} >Más información</Button>}
            />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}

import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../utils/axios';
//
import { dispatch } from './store';

const initialState = {
  isLoading: false,
  code: '',
  error: null,
  properties: [],
  states: [],
  types : [],
  operations: [],
  agent: null,
  propertiesAgent: [],
  questions: [],
  agents: [],
  filter: {
    properties: [],
    pages: 1,
    page: 0
  }
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
   
    // GET PROPERTIES
    getProperties(state, action) {
      state.isLoading = false;
      state.properties = action.payload.properties;
      state.states = action.payload.states;
      state.types = action.payload.types;

    },
    setCode(state, action) {
      state.code = action.payload;
    },
    // GET OPERATIONS
    getOperations(state, action) {
      state.operations = action.payload.operations;
    },
    clearOperations(state) {
      state.operations = [];
    },
    // GET AGENT
    getAgent(state, action) {
      state.isLoading = false;
      state.agent = action.payload.agent;
      state.propertiesAgent = action.payload.propertiesAgent;
      state.questions = action.payload.questions;
    },
    createQuestion(state, action) {
      state.isLoading = false;
      state.questions.push(action.payload.question);
    },
    getAgents(state, action) {
      state.agents = action.payload.agents;
    },
    getPropertiesFilter(state, action) {
      state.isLoading = false;
      state.filter.properties = action.payload.properties;
      state.filter.pages = action.payload.pages;
      state.filter.page = action.payload.page;
    }
  },
});

// Reducer
export default slice.reducer;

export function getProperties() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const { data } = await axios.get('https://api.ipregistry.co/?key=q44qmr4b2001lm05')
      dispatch(slice.actions.setCode('UY')); // data.location.country.code
      const response = await axios.get(`/api/portal/home/get-properties?code=${'UY'}`); // data.location.country.code
      dispatch(slice.actions.getProperties(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOperations(id) {
  return async () => {
    try {
      dispatch(slice.actions.clearOperations());
      const response = await axios.get(`/api/portal/home/get-operation/${id}`);
      dispatch(slice.actions.getOperations(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDataAgent(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/portal/agent/properties/${id}`);
      dispatch(slice.actions.getAgent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createQuestion(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/portal/agent/questions/`, data);
      dispatch(slice.actions.createQuestion(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgents() {
  return async () => {
    try {
      const response = await axios.get(`/api/portal/agents/all`);
      dispatch(slice.actions.getAgents(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPropertiesFilter(query) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
     // const { data } = await axios.get('https://api.ipregistry.co/?key=q44qmr4b2001lm05')
       dispatch(slice.actions.setCode('UY')); // data.location.country.code
      const response = await axios.get(`/api/portal/get-properties-filter?code=${'UY'}&${query}`); // data.location.country.code
      dispatch(slice.actions.getPropertiesFilter(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createEmail(body) {
  return async () => { // eslint-disable-line 
    try {
      const response = await axios.post(`/api/portal/send-email`, body);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
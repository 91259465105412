import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';

// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(15, 0),
}));

// ----------------------------------------------------------------------

export default function HomeColorPresets() {

  return (
    <RootStyle>
      <Container sx={{ position: 'relative', textAlign: 'center' }}>
        <MotionInView variants={varFade().inUp}>
          <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
          Lorem Ipsum
          </Typography>
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <Typography variant="h2" sx={{ mb: 3 }}>
          Where does it come from?
          </Typography>
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <Typography
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'text.primary'),
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </Typography>
        </MotionInView>


        <Box sx={{ position: 'relative' }}>
          <Image
            disabledEffect
            alt="grid"
            src="https://minimal-assets-api.vercel.app/assets/images/home/theme-color/grid.png"
          />

          <Box sx={{ position: 'absolute', paddingLeft: 40, top: 0 }}>
            <MotionInView variants={varFade().inUp}>
            <m.div animate={{ y: [0, -15, 0] }} transition={{ duration: 8, repeat: Infinity }}>
              <Image
              sx={{borderRadius: 10}}
                disabledEffect
                alt="screen"
                src={`https://i.ibb.co/s2XTbgW/Sin-ti-tulo-1.png`}
              />
                </m.div>
            </MotionInView>
          </Box>
        </Box>
      </Container>
    </RootStyle>
  );
}

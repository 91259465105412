import * as Yup from 'yup';
// form
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Stack, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { createQuestion } from '../../../redux/client';
import {useDispatch } from '../../../redux/store';

// ----------------------------------------------------------------------

const RootStyles = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.neutral,
}));

// ----------------------------------------------------------------------
BlogPostCommentForm.propTypes = {
  idProperty: PropTypes.string,
  handleUpdate: PropTypes.func,
  idAgent: PropTypes.string,
};
export default function BlogPostCommentForm({ idProperty, handleUpdate, idAgent }) {
  const CommentSchema = Yup.object().shape({
    comment: Yup.string().required('El comentario es requerido'),
    name: Yup.string().required('El nombre es requerido'),
    email: Yup.string().email('El email debe ser una dirección válida').required('El email es requerido'),
    phone: Yup.string().required('El teléfono es requerido'),
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    "comment": '',
    "name": '',
    "email": '',
    "phone": '',
    "idProperty": idProperty,
    "dateCreated": new Date(),
    "idAgent": idAgent,
  };

  const methods = useForm({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      const values = getValues();
      await dispatch(createQuestion(values));
      handleUpdate(values)
      enqueueSnackbar('Se creo correctamente la pregunta', { variant: 'success' });
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyles>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        Realizar consulta
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField name="comment" label="Ingrese aqui su consulta *" multiline rows={3} />

          <RHFTextField name="name" label="Nombre *"/>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <RHFTextField name="email" label="Email *" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField name="phone" label="Teléfono" />
            </Grid>
          </Grid>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Enviar
          </LoadingButton>
        </Stack>
      </FormProvider>
    </RootStyles>
  );
}

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import DialogScrolling from '../../components/DialogScrolling';

// const
import {terms, privacy} from './data';
// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'BenBoot',
    children: [
      { name: 'Contacta con nosotros', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terminos y Condiciones', href: '#', id: 'terms' },
      { name: 'Politicas de Privacidad', href: '#', id: 'privacy' },
    ],
  },
  {
    headline: 'Contacto',
    children: [
      { name: 'info@benboot.com', href: '#' },
      { name: '25 de Mayo 713', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const [title, setTitle] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (id) => {
    if (id === 'terms') {
      setText(terms)
      setOpen(true); 
      setTitle('Terminos y Condiciones');
    } else if (id === 'privacy') {
      setText(privacy)
      setTitle('Politicas de Privacidad');
      setOpen(true); 
    }
  }
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Portal Inmobiliario
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      onClick={() => handleOpen(link.id)}
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2022. All rights reserved
        </Typography>
      </Container>
      <DialogScrolling open={open} close={handleClose} text={text} title = {title}/>
    </RootStyle>
  );
}

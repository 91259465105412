import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, List } from '@mui/material';
//
import BlogPostCommentItem from './BlogPostCommentItem';

// ----------------------------------------------------------------------

BlogPostCommentList.propTypes = {
  questions: PropTypes.object.isRequired,
};

export default function BlogPostCommentList({ questions }) {
  return (
    <List disablePadding>
      {questions &&
        questions.map((comment) => {
          const { response } = comment;
          const hasReply = response && response.length > 0;

          return (
            <Box key={comment._id} sx={{}}>
              <BlogPostCommentItem
                name={comment.name}
                avatarUrl={comment.avatarUrl}
                createdAt={comment.dateCreated}
                message={comment.comment}
              />

             {hasReply &&
                response.map((reply) => (
                  <BlogPostCommentItem
                    key={comment._id}
                    message={reply.comment}
                    tagUser={reply && reply.tagUser}
                    postedAt={reply.dateCreated}
                    name={reply.displayName}
                    avatarUrl={reply && reply.image}
                    hasReply
                  />
                ))}
            </Box>
          );
        })}
    </List>
  );
}

// routes
/*eslint-disable */

import { PATH_AUTH, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------
// Es aqui
const ICON_SIZE = {
  width: 22,
  height: 22,
};

export const menuLogin = [
  {
    title: 'Inicio',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Buscar Inmuebles', 
    icon: <Iconify icon={'fluent:real-estate-20-filled'} {...ICON_SIZE} />,
    path: PATH_PAGE.properties,
  },
  {
    title: 'FAQs',
    icon: <Iconify icon={'akar-icons:chat-question'} {...ICON_SIZE} />,
    path: PATH_PAGE.faqs,
  },
  {
    title: 'Contáctanos',
    icon: <Iconify icon={'fluent:contact-card-16-filled'} {...ICON_SIZE} />,
    path: PATH_PAGE.contact,
  },
]

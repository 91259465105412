import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import productReducer from './slices/product';
import countriesReducer from './countries';
import categoriesReducer from './categories';
import clientsReducer from './client';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({

  product: persistReducer(productPersistConfig, productReducer),
  countries: countriesReducer,
  categories: categoriesReducer,
  client: clientsReducer,
});

export { rootPersistConfig, rootReducer };

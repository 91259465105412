import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography, Grid } from '@mui/material';

// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(5),
  justifyContent: 'center',
  height: theme.spacing(5),
  marginBottom: theme.spacing(4),
  color: theme.palette.primary.main,
  backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
}));

// ----------------------------------------------------------------------

BlogPostTags.propTypes = {
  post: PropTypes.object.isRequired,
};

export default function BlogPostTags({ post }) {
  const { attributes } = post;

  function setIcons(icon) {
    if (icon === 'FULL_BATHROOMS') {
      return 'cil:bath';
    }
    if (icon === 'BEDROOMS') {
      return 'mdi:bed-outline';
    }
    if (icon === 'PARKING_LOTS') {
      return 'mdi:parking';
    }
    if (icon === 'WAREHOUSES') {
      return 'mdi:warehouse';
    }
    if (icon === 'ROOMS') {
      return 'mdi:home-outline';
    }
    if (icon === 'PROPERTY_AGE') {
      return 'mdi:home-outline';
    }
    if (icon === 'MAINTENANCE_FEE') {
      return 'mdi:account-cash-outline';
    }
    if (icon === 'TOTAL_AREA') {
      return 'bx:area';
    }
    if (icon === 'LAND_AREA') {
      return 'gis:layer-landcover';
    }
    if (icon === 'COVERED_AREA') {
      return 'bxs:area';
    }
    if (icon === 'BALCONY_AREA') {
      return 'ic:baseline-balcony';
    }
    return 'ic:round-verified';
  }

  return (
    <Box>
      <Grid container>
   
        {attributes
          .filter((items) => items.value_type === 'number_unit' || items.value_type === 'number')
          .map((item) => (
            <Grid item xs={6} md={3} key={item.id}>
              <Box sx={{ my: 1.5, mx: 'auto', maxWidth: 280, textAlign: 'center' }}>
                <IconWrapperStyle>
                  <Iconify icon={setIcons(item.id)} width={30} height={30} />
                </IconWrapperStyle>
                <Typography variant="subtitle1" gutterBottom>
                  {item.name}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{item.value_name}</Typography>
              </Box>
            </Grid>
          ))}
      </Grid>

    </Box>
  );
}

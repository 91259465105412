import PropTypes from 'prop-types';
// @mui
import { Typography, Box, Paper, Container, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(4),

  borderRadius: Number(theme.shape.borderRadius) * 2,
  
}));

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
FaqsCategory.propTypes = {
  types: PropTypes.array,
}
export default function FaqsCategory({types}) {
  
  function search() {

    const apt = {}
    const apartamento = types.find(type => type.name === "Apartamentos")
    const departamentos = types.find(type => type.name === "Departamentos")

    if( apartamento !== undefined ){
      apt.value = apartamento.name
    } else if (departamentos !== undefined) {
      apt.value = departamentos.name
    } else {
      return apt.value = "Departamentos"
    }
    return apt.value
  }
  const CATEGORIES = [
    {
      label: `${search()}`,
      icon: 'https://s3.amazonaws.com/benboot.com/Images/icons/apartament_icon.png',
      href: `${PATH_PAGE.properties}?type=Apartamentos`,
    },
    {
      label: 'Casas',
      icon: 'https://s3.amazonaws.com/benboot.com/Images/icons/home_icon.png',
      href: `${PATH_PAGE.properties}?type=Casas`,
    },
    {
      label: 'Locales',
      icon: 'https://s3.amazonaws.com/benboot.com/Images/bussines_icon.png',
      href: `${PATH_PAGE.properties}?type=Locales`,
    },
    {
      label: 'Oficinas',
      icon: 'https://s3.amazonaws.com/benboot.com/Images/oficina_icon.png',
      href: `${PATH_PAGE.properties}?type=Oficinas`,
    },
    {
      label: 'Campos',
      icon: 'https://s3.amazonaws.com/benboot.com/Images/icons/campos_icons.png',
      href: `${PATH_PAGE.properties}?type=Campos`,
    },
  ];

  return (

    <Container>
    <ContentStyle>
    <Box
      sx={{
        mb: 2,
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(5, 1fr)',
        },
      }}
    >
      {CATEGORIES.map((category) => (
        <MotionInView key={category.label} variants={varFade().in}>
          <CategoryCard category={category} />
        </MotionInView>
      ))}
    </Box>
    </ContentStyle>
  </Container>
  );
}

// ----------------------------------------------------------------------

CategoryCard.propTypes = {
  category: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
  }),
};

function CategoryCard({ category }) {
  const { label, icon, href } = category;

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        height: 200,
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
    >
      <Link href={href}>
      <Image visibleByDefault disabledEffect src={icon} sx={{ mb: 2, width: 80, height: 80 }} />
      <Typography variant="subtitle2">{label}</Typography>
      </Link>
    </Paper>
  );
}

/*eslint-disable */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// components
import LoadingScreen from '../components/LoadingScreen';
import { HomeAdvertisement } from 'src/sections/home';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Suspense fallback={<LoadingScreen isDashboard={false} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [

        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'contact-us', element: <Contact /> },
        { path: 'property-details/:id', element: <BlogPost /> },
        { path: '/:id', element: <UserProfile /> },
        { path: 'search/properties', element: <SearchProperties /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home'))); // eslint-disable-next-line 
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));

// Contact
const Contact = Loadable(lazy(() => import('../pages/Contact')));

// UserProfile
const UserProfile = Loadable(lazy(() => import('../pages/UserProfile')));

const SearchProperties = Loadable(lazy(() => import('../pages/SearchProperties'))); // eslint-disable-next-line 

const Faqs = Loadable(lazy(() => import('../pages/Faqs'))); // eslint-disable-next-line

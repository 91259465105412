import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, Card, Avatar, CardHeader, Typography } from '@mui/material';
import {paramCase} from 'change-case';
// utils
import { fDate } from '../../../../utils/formatTime';
// components
import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 30,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingleft: theme.spacing(3),
  borderRadius: '10%',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

// ----------------------------------------------------------------------

AppTopAuthors.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTopAuthors({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ p: 3 }}>
        {orderBy(list).map((author, index) => (
          <AuthorItem key={author._id} author={author} index={index} />
        ))}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

AuthorItem.propTypes = {
  author: PropTypes.shape({
    _id: PropTypes.string,
    image: PropTypes.string,
    country: {},
    createdAt: PropTypes.string,
    displayName: PropTypes.string,
    company: PropTypes.string,
  }),
  index: PropTypes.number,
};

function AuthorItem({ author, index }) {
  const handleClick = () => {
    const paths = paramCase(author.company ?? author.displayName)
    window.location.href = `/${paths.toLowerCase()}`;
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1.5 }}>
      <Avatar alt={author.displayName} src={author.image} />

      <Box sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleClick}>
        <Typography variant="subtitle2">{author.displayName}</Typography>

        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          <Iconify icon={'akar-icons:calendar'} sx={{ width: 16, height: 16, mr: 0.5 }} />
          {fDate(author.createdAt)}
        </Typography>
      </Box>

      <IconWrapperStyle
        sx={{
          ...(index === 1 && {
            color: 'info.main',
            bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
          }),
          ...(index === 2 && {
            color: 'error.main',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
        }}
      >
        <Image src={`https://countryflagsapi.com/png/${author.country.id.toLowerCase()}`} />
      </IconWrapperStyle>
    </Stack>
  );
}
